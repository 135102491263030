import React from 'react';
  
const WebIcon = () => {
  return (
    <img 
        className="web"
        src={process.env.PUBLIC_URL + "/web.png"}
        alt=""
        width="13"
        height="12">
    </img>
  );
};
  
export default WebIcon;