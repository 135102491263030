import "./login.css"
import { config } from "../config";

function AdminLogin() {
	const googleAuth = () => {
		window.open(
			`${config.backendBASEurl}/api/auth/google/callback`,
			"_self"
		);
	};
	return (
		<div className="signInDiv">
            <button className="signInBut" onClick={googleAuth}>
                <img className="signInImg" src={process.env.PUBLIC_URL + "/google.png"} alt="google icon" />
                <span>Sign in with Google</span>
            </button>
		</div>
	);
}

export default AdminLogin;