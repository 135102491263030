import { useRef, useLayoutEffect } from "react";

import './ProductRow.css';
import axios from "axios";
import { config } from "../../config";
import { NavLink } from "react-router-dom";

const ProductRow = ({ name, _id , rowHeightHandler, refresh }) => {

    const ProdRowRef = useRef();

    const deleteProdHandler = () => {
        axios.delete(config.backendBASEurl+"/api/products/"+_id).then(()=>refresh()).catch(()=>refresh());
    }

    useLayoutEffect(() => {
        if (rowHeightHandler) {
            rowHeightHandler(ProdRowRef.current.scrollHeight);
        }
    }, [rowHeightHandler]);

    return (
        <div className="LastProdRow" ref={ProdRowRef}>
            <h5>{name}</h5>
            <NavLink className="LastProdRowEditNav" to= {{pathname:"/editproduct"}} state={{_id:_id}}><button className="LastProdRowEdit">Edit</button></NavLink>
            <button className="LastProdRowDelete" onClick={deleteProdHandler}>Delete</button>
        </div>
    );

};

export default ProductRow;