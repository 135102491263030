import { useNavigate } from "react-router-dom";

const DropdownSub = (props)=>{

  const currentLocation = window.location.pathname;
  const navigate = useNavigate();

    const handleClickScroll = (id) => {

      props.dropdown(false);
        if (currentLocation === "/products") {

          const element = document.getElementById(id);
      
          if (element) {
            var headerOffset = 45;
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition + document.documentElement.scrollTop - headerOffset;
        
            window.scrollTo({
              top: offsetPosition,
              behavior: "smooth"
            });
          }
        }
        else {
          navigate("/products", { state : id });
        }
      };
    

    const subs = props.subcats.subcategory?
    props.subcats.subcategory.map(x=>
    <div className="DCSubcat">
        <h3 onClick={()=>handleClickScroll(x._id)}>{x.name}</h3>
            {x.group && x.group.length >0 ? 
                x.group.map(g=>
                                <div className="DCGroupCont">
                                    <h4 onClick={()=>handleClickScroll(g._id)}>{g.name}</h4>
                                    {g.product&&g.product.length>0?
                                    g.product.map(p=><p onClick={()=>handleClickScroll(p._id)}>{p.name}</p>):""}
                                </div>)
            :""}
    </div>)
    :"";
    
    return (<div className="DCSubcatCont">{subs}</div>);
};

export default DropdownSub;