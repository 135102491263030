import React from 'react';
import "./solutions.css"
  
const Solutions = () => {
  return (
    <>
      <div className='solutions-header'>
        <h1>Solutions</h1>
      </div>
      <div className='solutions-container'>
        <div>
        Welcome to Etechpro's product solutions page, where we offer a wide range of innovative and reliable infrastructure solutions 
        tailored to meet your specific needs. Explore our diverse product offerings:
        </div>
        <div className='solutions-rows'>
          <div className='solutions-item'>
            <img
              src={process.env.PUBLIC_URL + "/solutions-1.png"}
              alt=''></img>
            <h4>ICT and ELV System Cabling Infrastructure:</h4>
            <p>Our ICT and ELV system cabling solutions are designed to provide seamless connectivity and support for information and 
              communication technology systems. From structured cabling to fiber optics, we offer reliable solutions that ensure efficient 
              data transmission and support the integration of ELV systems like access control, CCTV, fire alarm systems, and more.</p>
          </div>
          <div className='solutions-item'>
            <img
              src={process.env.PUBLIC_URL + "/solutions-2.png"}
              alt=''></img>
            <h4>Data Center Cabling, Pathways, Raised Floor Infrastructure:</h4>
            <p>For data center environments, we provide comprehensive solutions for cabling, pathways, and raised floor infrastructure. 
              Our products are engineered to optimize airflow, enhance cable management, and support the high-density requirements of modern 
              data centers. From high-performance cables to intelligent cable management systems, we ensure your data center operates with 
              maximum efficiency and reliability.</p>
          </div>
        </div>
        <div className='solutions-rows'>
          <div>
            <img
              src={process.env.PUBLIC_URL + "/solutions-3.png"}
              alt=''></img>
              <h4>Data Networking Infrastructure:</h4>
            <p>As a leader in networking solutions, we offer a wide range of products to support your data networking infrastructure. 
              Our offerings include switches, routers, network cabinets, and patch panels, ensuring seamless connectivity and efficient network 
              management. Trust our reliable and high-performance networking solutions to optimize your data network's performance and scalability.</p>
          </div>
          <div>
            <img
              src={process.env.PUBLIC_URL + "/solutions-4.png"}
              alt=''></img>
            <h4>Security System Infrastructure:</h4>
            <p>Security is paramount in today's world. Our security system infrastructure solutions provide the foundation for effective security 
              systems. From cabling and connectors to access control panels and surveillance equipment, we offer a comprehensive range of products 
              to support your security infrastructure needs. Trust our solutions to enhance the safety and protection of your premises.</p>
          </div>
        </div>
        <div className='solutions-rows'>
          <div>
            <img
              src={process.env.PUBLIC_URL + "/solutions-5.png"}
              alt=''></img>
            <h4>Energy Management System Infrastructure:</h4>
            <p style={{maxWidth:"none"}}>• Efficient energy management is crucial for sustainability and cost savings. Our energy management system 
            infrastructure solutions encompass a range of products, including cabling, sensors, and control panels, to enable efficient monitoring and 
            control of energy usage. By implementing our solutions, you can optimize energy efficiency and make informed decisions for energy management.
            <br/>• At Etechpro, we are committed to providing top-quality infrastructure solutions that meet the highest industry standards. Our team of 
            experts is ready to assist you in selecting the right products, designing the optimal infrastructure, and providing ongoing support to ensure
            your project's success.<br/>
            • Contact us today to discuss your infrastructure needs and discover how Etechpro can provide reliable, innovative, and tailored solutions 
            for your ICT and ELV cabling, data center, data networking, security system, and energy management system infrastructure requirements.</p>
          </div>
        </div>
      </div>
    </>
  );
};
  
export default Solutions;