import { useLayoutEffect, useState } from 'react';
import './EPLProduct.css';
import ProductRow from './ProductRow';
import { NavLink } from 'react-router-dom';

const EPLProduct = (props) => {

    const [rowHeight,setRowHeight] = useState(0);

    const productRows = props.products?props.products.length>0? 
    props.products.map((p,l)=>l===0?<ProductRow name={p.name} _id={p._id} refresh={props.refresh} rowHeightHandler={setRowHeight}/>:
    <ProductRow name={p.name} _id={p._id} refresh={props.refresh}/>) :"":"";

    useLayoutEffect(()=>props.heightHandler((rowHeight+6)*(props.products.length||0)))

    return(
    <>
        <div className="EPLProdCont">
            {productRows}
        </div>
        <NavLink to= {{pathname:"/addproduct"}} state={{category:props.category,subcategory:props.subcategory,group:props.group}}>
            <button className='LastAddProductBut'>Add Product</button>
        </NavLink>
    </>);
};

export default EPLProduct;