import { useState } from "react";
import './EPLGroup.css';
import axios from "axios";
import { config } from "../../config";
import EPLProduct from "./EPLProduct";


const EPLGroup = (props) => {

    const [toggle, setToggle] = useState(false);
    const [toggle2, setToggle2] = useState(false);

    const [heightEl, setHeightEl] = useState(0);

    const toggleState = () => {
        setToggle(!toggle);
    };

    const toggle2State = () => {
        setToggle2(!toggle2);
    };

    const deleteGroupHandler = () => {
        axios.delete(config.backendBASEurl+"/api/groups/"+props._id).then(()=>props.refresh()).catch(()=>props.refresh());
    };

    return(
        <div className="EPLGroupDropper">
            <div className="EPLGroupR1">
            <button className={"selectedGroupBut"} onClick={toggle2State}><h4 style={{margin:"0px"}}>{props.name}</h4>
                <img className={toggle ? "active rightImg": "rightImg"}
                    alt=""
                    src={process.env.PUBLIC_URL + "/3dots.png"}
                    onClick={toggleState}>
                </img>
                <div className={toggle ? "accordion2-toggle animated2" : "accordion2-toggle"}
                style={{width: toggle ? "8vw" : "0px"}}>
                <button style={{width: toggle ? "9vw" : "0px"}} onClick={deleteGroupHandler} aria-hidden={toggle ? "true" : "false"} disabled={!toggle}>Delete</button>
                </div>
                <div className="groupchev">
                <img className={toggle2 ? "chevactive" :""} alt=""
                    src={process.env.PUBLIC_URL + "/chevron.png"}></img></div>
            </button>
            
            <div className={toggle2 ? "accordion3-toggle animated3" : "accordion3-toggle"}
            style={{height: toggle2 ? `${heightEl}px` : "0px"}}>
                {toggle2 && <EPLProduct products={props.products} heightHandler={setHeightEl} 
                refresh={props.refresh} category={props.category} subcategory={props.subcategory} group={props._id}/>}
            </div>
            </div>
        </div>
    );


};

export default EPLGroup;