import React from 'react';
  
const PhoneIcon = () => {
  return (
    <img 
        className="phone"
        src={process.env.PUBLIC_URL + "/phone.png"}
        alt=""
        width="10"
        height="10">
    </img>
  );
};
  
export default PhoneIcon;