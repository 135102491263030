import axios from "axios";
import { config } from "../../config";

const DisplayImageRow = (props) => {

    const deleteImage = () => {
        axios
            .delete(config.backendBASEurl + "/api/homepageImages/" + props._id)
            .then(()=>props.handler())
            .catch(err=>console.log(err));
    }

    const changeImage = (e) => {
        const formdata = new FormData();
        formdata.append('image', e.target.files[0]);
        axios
        .put(config.backendBASEurl + "/api/homepageImages/" + props._id, formdata)
        .then(()=>props.handler())
        .catch(err=>console.log(err));
    }

    const inputId = `filesEdit-${props._id}`;

    return (
    <div className="displayImageRow">
        <p className="DIRslot">{props.slot}</p>
        <img 
          className="DIRimage" 
          src={config.backendBASEurl + "/homepage/"+ props.image}
          alt="No preview available"
          width="18vw"
        ></img>
        <p className="DIRname">{props.name}</p>
        <button type="button" className="DIRButton1">
            <input 
              type="file" 
              id={inputId} 
              style={{display:"none"}} 
              accept="image/jpeg, images/jpg" 
              onChange={e=>changeImage(e)}/>
            <label htmlFor={inputId} className="changeImagelab">
                <p>Change Image</p>
            </label>
        </button>
        <button type="button" className="DIRButton2" onClick={deleteImage}>
            Delete Image
        </button>
    </div>);
};

export default DisplayImageRow;