import axios from "axios";
import { config } from "../../config";
import { useRef } from "react";

const AddGroup = (props) => {

    const groupRef = useRef(null);
    
    const addGroupHandler = () => {
        if (groupRef.current.value.length >2){
        axios.post(config.backendBASEurl + "/api/groups/",
        {
            "name":groupRef.current.value,
            "category":props.category,
            "subcategory":props.subcat
        }).then(()=>{
            groupRef.current.value = "";
            props.refresh();}).catch(()=>{
                props.refresh()})};
    };

    return(
        <div className="EPLaddGroup">
            <input ref={groupRef} type="text" maxLength={50} placeholder="Enter Group Name"></input>
            <button onClick={addGroupHandler}>Add Group</button>
        </div>
    );
};

export default AddGroup;