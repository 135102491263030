import React from 'react';
import './solutions.css';
  
const AboutUs = () => {
  return (
    <>
      <div className='solutions-header'>
        <img
        src={process.env.PUBLIC_URL + "/EtechproLogo21.png"}
        alt=''
        className='aboutus-image'>
        </img>
      </div>
      <div className='solutions-container'>
        <div style={{textAlign:"justify"}}>
        Etechpro is a US-based company that specializes in the design and manufacturing of telecom structured cabling and data networking 
        products. We have a strong focus on providing high-quality solutions for efficient connectivity in the telecommunications and data 
        networking industry. With our expertise in the field, Etechpro offers a comprehensive range of products including cables, connectors, 
        patch panels, and other networking equipment. These products are designed to ensure reliable and high-speed data transmission, 
        supporting the needs of various industries and organizations.
        <br/>At Etechpro, we are a leading provider of cutting-edge telecom structured cabling and data networking solutions. 
        With our unwavering commitment to innovation and quality, we empower businesses and organizations to build efficient, reliable, 
        and scalable network infrastructure. <br/>Our Mission:Our mission is to be the preferred partner for our customers in their journey 
        towards seamless connectivity. We strive to deliver innovative, high-quality products that enable businesses to harness the full 
        potential of their networks while meeting their unique requirements.
        </div>
        <br/>
        <div className='solutions-rows'>
          <div className='solutions-item'>
            <img
              src={process.env.PUBLIC_URL + "/aboutus-1.png"}
              alt=''></img>
            <h4>Innovation at the Core:</h4>
            <p>Innovation drives everything we do at Etechpro. We understand that in a rapidly evolving industry, staying ahead of the 
              curve is crucial. That's why we invest in research and development, closely monitor technological advancements, and 
              collaborate with industry leaders to bring cutting-edge solutions to the market. By harnessing the power of innovation, 
              we empower our customers to embrace new possibilities and unlock enhanced network performance.</p>
          </div>
          <div className='solutions-item'>
            <img
              src={process.env.PUBLIC_URL + "/aboutus-2.png"}
              alt=''></img>
            <h4>Quality as a Priority:</h4>
            <p>Quality is ingrained in our DNA. We set high standards for ourselves and take pride in delivering products that meet or 
              exceed industry benchmarks. From meticulous product design to rigorous manufacturing processes, we leave no stone unturned
               in ensuring the utmost quality and reliability of our offerings. Our dedication to quality assurance ensures that our 
               customers can depend on our products for seamless connectivity, enhanced productivity, and peace of mind.</p>
          </div>
        </div>
        <div className='solutions-rows'>
          <div>
            <img
              src={process.env.PUBLIC_URL + "/aboutus-3.png"}
              alt=''></img>
              <h4>Sustainability for a Better Future:</h4>
            <p>At Etechpro, we are committed to sustainability and minimizing our environmental impact. We embrace sustainable practices 
              throughout our operations, from eco-friendly manufacturing processes to the use of recyclable materials. By offering 
              energy-efficient and environmentally conscious solutions, we strive to contribute to a greener future and empower our 
              customers to build sustainable network infrastructure.*Customer-Centric Approach:*Our customers are at the heart of 
              everything we do. We understand that each organization has unique networking requirements, and we work closely with our 
              clients to provide tailored solutions that address their specific needs. We believe in building strong, long-term 
              relationships with our customers, and our dedicated support team is always ready to provide exceptional service, technical 
              expertise, and timely assistance.</p>
          </div>
          <div>
            <img
              src={process.env.PUBLIC_URL + "/aboutus-4.png"}
              alt=''></img>
            <h4>Join Us in Empowering Connectivity:</h4>
            <p>Experience the Etechpro difference and partner with us to unlock the true potential of your network infrastructure. 
              Whether you're building a new network, expanding your existing infrastructure, or seeking innovative solutions, we are 
              here to support you every step of the way. Let's embark on a journey of seamless connectivity, innovation, and quality 
              together.Contact us today to discover how Etechpro can transform your network infrastructure and propel your business forward.</p>
          </div>
        </div>
        <div className='solutions-rows'>
          <div>
            <img
              src={process.env.PUBLIC_URL + "/whyChoose.png"}
              alt=''
              className='aboutus-whyChoose'></img>
            <p style={{maxWidth:"none"}}>Get in touch with our knowledgeable team to discuss your telecom structured cabling and data 
            networking requirements. We are here to assist you every step of the way. Choose Etechpro for reliable, efficient, and 
            future-proof networking solutions that drive your success. Experience the Etechpro advantage today!</p>
          </div>
        </div>
      </div>
    </>
  );
};
  
export default AboutUs;