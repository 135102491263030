import axios from "axios";
import { config } from "../../config";
import { useRef } from "react";



const AddCatSection = (props) => {
    
    const catRef = useRef(null);
    
    const addCatHandler = () => {
        if (catRef.current.value.length >2){
        axios.post(config.backendBASEurl + "/api/categories/",
        {"name":catRef.current.value}).then(()=>{
            catRef.current.value = "";
            props.refresh();}).catch(()=>{
                props.refresh()})};
    };

    return (
        <div className='addCatCol'>
        <textarea ref={catRef} placeholder='Enter Category Name' maxLength={50}></textarea>
        <button onClick={addCatHandler}>Add Category</button>
        </div>
    );
};

export default AddCatSection;