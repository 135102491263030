import React, {useState} from 'react';

const ScrollButton = () =>{

const [visible, setVisible] = useState(false)

const toggleVisible = () => {
	const scrolled = document.documentElement.scrollTop;
	if (scrolled > 50){
	setVisible(true)
	}
	else if (scrolled <= 50){
	setVisible(false)
	}
};

const scrollToTop = () =>{
	window.scrollTo({
	top: 0,
	behavior: 'smooth'
	});
};

window.addEventListener('scroll', toggleVisible);

return (
	<img onClick={scrollToTop} alt="" className='scrolltotop' src={process.env.PUBLIC_URL + "/scrollToTop.png" } style={{display: visible ? 'inline' : 'none'}}
	 />
);
}

export default ScrollButton;
