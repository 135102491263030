import { useEffect, useState } from 'react';
import './dropdown.css';
import DropdownSub from './DropdownSub';

const Dropdown = (props) => {

    const [selectedCat, setSeletedCat] = useState(null);

    useEffect(()=>{
        if (props.productList.length > 0) {
            setSeletedCat(0);
        } 
    },[setSeletedCat,props]);

    const dropCategories = props.productList.map((x,i)=>
        <div className={selectedCat===i?'DCname DCnameActive':"DCname"} onClick={()=>setSeletedCat(i)}>{x.name}</div>);

    return (
    <div className="dropdown" onMouseLeave={()=>props.dropdown(false)}>
        <div className='dropdown-categories'>
            {dropCategories}
        </div>
        <DropdownSub subcats={props.productList[selectedCat]?props.productList[selectedCat]:""} dropdown={props.dropdown} prodList={props.productList?props.productList:""}/>
    </div>)
}

export default Dropdown;