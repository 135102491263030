import SubCatRight from "./SubCatRight";

const CategoriesRight = (props) => {
    
    const subcategories = props.subcat.map(s => <SubCatRight name={s.name} _id={s._id} group={s.group?s.group:""}/>) || "";

    return (
        <div className="categoriesRight" id={props._id}>
            <h2>{props.name}</h2>
            {subcategories}
        </div>
    );
};

export default CategoriesRight;