import axios from "axios";
import { config } from "../../config";
import { useRef } from "react";



const AddSubCatSection = (props) => {
    
    const subCatRef = useRef(null);
    
    const addCatHandler = () => {
        if (subCatRef.current.value.length >2){
        axios.post(config.backendBASEurl + "/api/subcategories/",
        {
            "name":subCatRef.current.value,
            "category":props.category
        }).then(()=>{
            subCatRef.current.value = "";
            props.refresh();}).catch(()=>{
                props.refresh()})};
    };

    return (
        <div className='addCatCol'>
        <textarea ref={subCatRef} placeholder='Enter Sub-category Name' maxLength={50}></textarea>
        <button onClick={addCatHandler}>Add Subcategory</button>
        </div>
    );
};

export default AddSubCatSection;