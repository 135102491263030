import React from 'react';
import "./warranty.css";
import EmailIcon from '../components/UI/EmailIcon';
  
const Warranty = () => {
  return (
    <>
    <div className='container-header'>
      <h1>Warranty</h1>
    </div>
    <div className='container-warranty' style={{marginTop:-20}}>
      <div>
        <h3>Structured cabling System Warranty:</h3>
        <p>&ensp; Etechpro offer a 25-year product and application warranty as standard on all installations completed by an accredited partner.<br/><br/>
        &ensp;  The Etechpro warranty covers:</p>
        <ul>
          <li>All Etechpro copper, fibre optic, voice cabling systems when installed as part of a complete ETechpro Cabling Solution</li>
          <li>Registered sites covered by 25-year application warranty</li>
          <li>Performance/Bandwidth warranty</li>
          <li>Permanent Link warranty as standard, channel on application</li>
          <li>Issued to latest published standards</li>
          <li>All warranties are processed through the Offline warranty system after site inspection and random testing of the cabling link and after an audit of test data, and at the discretion of the Etechpro technical support team.</li>
        </ul>
      </div>
      <div>
        <h3>Other Products Warranty:</h3>
        <p>&ensp; All Etech Products comes with 3 years warranty unless mentioned otherwise. Please refer the product data sheet.<br/></p>
        <p>&ensp; For more information on warranty details, please contact our sales team <EmailIcon/> sales@econnecti.com</p>
      </div>
    </div>
    </>
  );
};
  
export default Warranty;