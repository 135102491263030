import React from 'react';
  
const EmailIcon = () => {
  return (
    <img 
      className="email"
      src={process.env.PUBLIC_URL + "/email.png"}
      alt=""
      width="14"
      height="10">
    </img>
  );
};
  
export default EmailIcon;