import React from 'react';
  
const LocationIcon = () => {
  return (
    <img 
      className="location"
      src={process.env.PUBLIC_URL + "/location.png"}
      alt=""
      width="8"
      height="12">
    </img>
  );
};
  
export default LocationIcon;