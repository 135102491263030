import ProductCard from "./ProductCard";

const Group = (props) => {

    const products = props.product.map(p=><ProductCard name={p.name} _id={p._id} image={p.image}/>) || "";

    return (
        <div className="groupRight" id={props._id}>
            <h4>{props.name}</h4>
            <div className="productsRight">
                {products}
            </div>
        </div>
    );
};

export default Group;