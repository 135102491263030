import { useEffect, useRef, useState } from 'react';
import axios from "axios";
import { config } from "../../config";

const EPLSubCat = (props) => {

    const [toggle, setToggle] = useState(false);
    const [heightEl, setHeightEl] = useState();

    const refHeight = useRef();

    useEffect(() => {
        setHeightEl(`${refHeight.current.scrollHeight+5}px`)
    }, []);

    const toggleState = () => {
        setToggle(!toggle);
    };

    const clickHandler = () => {
        if (props.selectedSubCat !== props.index) props.handler(String(props.index))
    };

    const deleteSubCatHandler = () => {
        props.handler("1");
        axios.delete(config.backendBASEurl+"/api/subcategories/"+props._id).then(()=>props.refresh()).catch(()=>props.refresh());
    };

    return (
        <div className="EPLCategoryDropper">
            <div className="EPLCatR1">
                <button className={props.selected === String(props.index)?"selectedCatBut selectedCatButAct":"selectedCatBut"} onClick={clickHandler}>{props.name}</button>
                <img className={toggle ? "active": ""}
                    alt=""
                    src={process.env.PUBLIC_URL + "/3dots.png"}
                    onClick={toggleState}></img>
            </div>
            <div className={toggle ? "accordion1-toggle animated1" : "accordion1-toggle"}
            style={{height: toggle ? `${heightEl}` : "0px"}}
            ref={refHeight}>
                <button onClick={deleteSubCatHandler} aria-hidden={toggle ? "true" : "false"} disabled={!toggle}>Delete</button>
            </div>
        </div>
    );
};

export default EPLSubCat;