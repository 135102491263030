import Group from "./GroupRight";

const SubCatRight = (props) => {
    
    const groups = props.group.map(g=><Group name={g.name} _id={g._id} product={g.product?g.product:""}/>) || "";
    
    return (
        <div className="subcatRight" id={props._id}>
            <h3>{props.name}</h3>
            {groups}
        </div>
    );
};

export default SubCatRight;