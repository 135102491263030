import React from 'react';
import './home.css'
import Slider from '../components/Slider/Slider';
  
const Home = () => {
  return (
    <>
      <div className='homeBackground' style={{backgroundColor: "#000"}}>
        <div className='homecont1'>
          <h1>Welcome to&nbsp;<img alt="" style={{marginBottom:-10}} height={50} src={process.env.PUBLIC_URL + "/homepageIcon.png"}></img>techpro</h1>
          <p>Your Partner in Telecom Structured Cabling and Data Networking Solutions</p>
          <Slider className='home-slider'/>
        </div>
        <div className='homecont2'>
          <p>
          At Etechpro, we provide cutting-edge solutions for all your telecom structured cabling and data networking needs. 
          With our expertise and commitment to excellence, we are your trusted partner in building reliable and efficient network infrastructure.<br/><br/>
          Etechpro established in the heart of the United States, Etechpro has been at the forefront of the telecommunications industry for 
          over a decade. We specialize in the design, manufacturing, and distribution of high-quality telecom structured cabling and data 
          networking products. Our extensive range of solutions caters to diverse industries, including telecommunications, IT, healthcare, 
          finance, and more.<br/><br/>
          At Etechpro, we prioritize innovation, quality, and sustainability in everything we do. These core values drive our commitment to 
          delivering superior products and making a positive impact on the industry and the environment.<br/>
          Here's how Etechpro embodies innovation, quality, and sustainability:
          </p>
        </div>
      </div>
      <div className='homecont3'>
          <div className='homeitem'>
          <h1>Innovation</h1>
            <p>We believe in staying at the forefront of technological advancements. Our dedicated team of experts constantly explores 
              innovative solutions and embraces emerging trends to ensure that our products are designed to meet the evolving needs of the 
              telecom and data networking industry. By incorporating the latest technologies, we enable our customers to stay ahead of the 
              curve and achieve optimal performance in their network infrastructure.</p>
          </div>
          <div className='homeitem'>
          <h1>Quality</h1>
            <p>Quality is the foundation of our products and services. We adhere to stringent quality standards and processes at every stage
               of our operations, from design and manufacturing to distribution and customer support. By maintaining a robust quality 
               management system, we ensure that our products consistently meet or exceed industry benchmarks. Our focus on quality assurance 
               translates into reliable, durable, and high-performance telecom structured cabling and data networking products.</p>
          </div>
          <div className='homeitem'>
            <h1>Sustainability</h1>
            <p>We are deeply committed to sustainability and minimizing our environmental impact. We strive to reduce waste, conserve energy, and 
              make responsible choices in our manufacturing processes. Our products are designed to be energy-efficient and eco-friendly, helping 
              our customers achieve their sustainability goals. Additionally, we actively seek out environmentally friendly materials and practices 
              to support a greener future.By combining innovation, quality, and sustainability, we aim to provide our customers with products that 
              enhance their network performance, reduce their environmental footprint, and contribute to a more sustainable future. At Etechpro, 
              we believe that through our dedication to these principles, we can make a positive difference in the industry while meeting the needs 
              of our customers.</p>
          </div>
        </div>
    </>

  );
};
  
export default Home;