import {NavLink, useNavigate, useLocation} from 'react-router-dom'
import './addProductPage.css'
import { useState, useRef } from 'react';
import axios from 'axios'; // import axios for making HTTP requests
import { config } from '../config';

const AddProductPage = () => {
    
    let location = useLocation();
    const formdataRef = useRef(new FormData());
    const navigate = useNavigate();

    const [formdataImage,setformdataImage] = useState(null); // change the initial state to null
    
    const nameRef = useRef(null);
    const productIdRef = useRef(null);
    const overviewRef = useRef(null);
    const specificationsRef = useRef(null);
    const featuresRef = useRef(null);
    const performanceRef = useRef(null);
    const electricalCharRef = useRef(null);
    const physicalCharRef = useRef(null);
    const environmentalCharRef = useRef(null);
    const packagingRef = useRef(null);
    const certificationsRef = useRef(null);

    const handleAddProductImage = (e) => {
        setformdataImage(URL.createObjectURL(e.target.files[0])); // use URL.createObjectURL to create a blob URL for the image
        formdataRef.current.set('image', e.target.files[0]);
    }

    const handleAddProduct = () => {

        // get all the textarea and input values
        let name = nameRef.current.value;
        let productId = productIdRef.current.value;
        let overview = overviewRef.current.value;
        let specifications = specificationsRef.current.value;
        let features = featuresRef.current.value;
        let performance = performanceRef.current.value;
        let electricalChar = electricalCharRef.current.value;
        let physicalChar = physicalCharRef.current.value;
        let environmentalChar = environmentalCharRef.current.value;
        let packaging = packagingRef.current.value;
        let certifications = certificationsRef.current.value;
        let group = location.state.group;

        // check if all the values are populated and has a minimum length of 3 char
        if (name.length < 3 || productId.length < 3) {
            alert("Please fill the name and ID fields with at least 3 characters");
            return;
        }

        // append all the values to formdata
        formdataRef.current.set('group',group);
        formdataRef.current.set('name', name);
        formdataRef.current.set('productID', productId);
        formdataRef.current.set('overview', overview);
        formdataRef.current.set('specification', specifications);
        formdataRef.current.set('features', features);
        formdataRef.current.set('performance', performance);
        formdataRef.current.set('electricalChar', electricalChar);
        formdataRef.current.set('physicalChar', physicalChar);
        formdataRef.current.set('environmentalChar', environmentalChar);
        formdataRef.current.set('packaging', packaging);
        formdataRef.current.set('certifications', certifications);

        axios.post(config.backendBASEurl + '/api/products', formdataRef.current, {headers:{'Content-Type': 'multipart/form-data'}})
            .then(response => {
                setformdataImage("");
                navigate("/admin");
            })
            .catch(error => {
                alert("Something went wrong"); 
            });
    }
    
    return (
    <div className="AddProductBody">
        <div className='AddProductHeader'>
            <h1>Add Product</h1>
        </div>
        <div className="AddProductForm">
            <div className="AddProductLeft">
                <img src={formdataImage} alt="Upload Product Image"></img>
                <input type="file" id="ProductAdd" style={{display:"none"}} accept="image/jpeg, images/jpg" onChange={e=>handleAddProductImage(e)}/>
                <label className="addSlot" htmlFor="ProductAdd"><p>Upload Image</p></label>
            </div>
            <div className="AddProductRight">
                <div className='AddProductRest'>
                    <h3>Name:</h3>
                    <input type="text" ref={nameRef}></input>
                </div>
                <div className='AddProductRest'>
                    <h4>Product ID:</h4>
                    <input type="text" ref={productIdRef}></input>
                </div>
                <div className='AddProductRest'>
                    <h4>Overview:</h4>
                    <textarea ref={overviewRef}></textarea>
                </div>
                <div className='AddProductRest'>
                    <h4>Specifications:</h4>
                    <textarea ref={specificationsRef}></textarea>
                </div>
                <div className='AddProductRest'>
                    <h4>Features:</h4>
                    <textarea ref={featuresRef}></textarea>
                </div>
                <div className='AddProductRest'>
                    <h4>Performance:</h4>
                    <textarea ref={performanceRef}></textarea>
                </div>
                <div className='AddProductRest'>
                    <h4>Electrical Characteristics:</h4>
                    <textarea ref={electricalCharRef}></textarea>
                </div>
                <div className='AddProductRest'>
                    <h4>Physical Characteristics:</h4>
                    <textarea ref={physicalCharRef}></textarea>
                </div>
                <div className='AddProductRest'>
                    <h4>Environmental Characteristics:</h4>
                    <textarea ref={environmentalCharRef}></textarea>
                </div>
                <div className='AddProductRest'>
                    <h4>Packaging:</h4>
                    <textarea ref={packagingRef}></textarea>
                </div>
                <div className='AddProductRest'>
                    <h4>Certifications:</h4>
                    <textarea ref={certificationsRef}></textarea>
                </div>
                <div className='AddProductButtons'>
                    <NavLink to= {{pathname:"/admin"}}><button className='red'>Cancel</button></NavLink>
                    <button className='green' onClick={handleAddProduct}>Add Product</button>
                </div>
            </div>
        </div>
    </div>);
};

export default AddProductPage;
