// Import React and React Router components
import React, { useEffect, useState } from "react";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import AboutUs from "./pages/aboutUs";
import Products from "./pages/products";
import ContactUs from "./pages/contactUs";
import Solutions from "./pages/solutions";
import Warranty from "./pages/warranty";
import Partners from "./pages/partners";
import Admin from "./pages/admin";
import Product from "./pages/Product";
import AddProductPage from "./pages/AddProductPage";
import EditProductPage from "./pages/EditProductPage";
import Dropdown from "./components/UI/Dropdown";
import { config } from "./config";
import axios from "axios";
import AdminLogin from "./pages/login";

function App() {

  const [user, setUser] = useState(null);

  const getUser = async () => {
		try {
			const url = `${config.backendBASEurl}/api/auth/login/success`;
			const { data } = await axios.get(url, { withCredentials: true });
			setUser(data.user._json);
		} catch (err) {
			console.log(err);
		}
	};

  useEffect(() => {
		getUser();
    axios.get(config.backendBASEurl + "/api/products/list").then((res)=>setProductList(res.data));
	},[]);

  const [showDropdown,setShowDropDown] = useState(false);

  const updateDropdown = (x) => setShowDropDown(x);

  const [productList, setProductList] = useState([]);

  return (
    <Router>
      <Navbar dropdown={updateDropdown}/>
      <div className="dropdown-container">
        {showDropdown && <Dropdown dropdown={updateDropdown} v={showDropdown} productList={productList}/>}
        <div className="bodyi">
          <Routes>
            <Route path="/*" element={<Home />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/products" element={<Products productList={productList}/>} />
            <Route path="/solutions" element={<Solutions />} />
            <Route path="/warranty" element={<Warranty />} />
            <Route path="/partners" element={<Partners />} />
            <Route path="/admin" element={user?<Admin />:<AdminLogin/>} />
            <Route path="/product/*" element={<Product />} />
            <Route path="/addproduct" element={<AddProductPage />}/>
            <Route path="/editproduct" element={<EditProductPage />}/>
          </Routes>
            <Footer className="footer"/>
        </div>
      </div>
    </Router>
  );
}

export default App;

