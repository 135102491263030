import { useState, useEffect } from "react";

import axios from "axios";
import { config } from "../config";

import './product.css'

const Product = () => {
    
    let temp = window.location.href.split("/");
    const _id = temp[temp.length-1];

    const lister = (text="") => {
        if (text.includes("\n")){
            return text.split("\n").map(t=>
            {if (t.includes("\\t")){
                return <ul className="sublists">{t.split("\\t").map(s=><li>{s}</li>)}</ul>
            }
            else {
                return <li>{t}</li>
            }
            })
        }
        return text
    }

    const [productDetails, setProductDetails] = useState([]);
    useEffect(() => {
        axios.get(config.backendBASEurl + "/api/products/" + _id).then((res)=>setProductDetails(res.data)).catch(()=>setProductDetails("Product does not exist."));
        }); 

    const { productID="", name="", overview="", specification="", features="", performance="", electricalChar="", physicalChar="", environmentalChar="", packaging="", certifications="", image="" } = productDetails

    return (<div className="product-cont-1">
        <div className="product-left1">
            <img alt="Product" src={config.backendBASEurl + "/images/"+ image}>
            </img>
        </div>
        <div className="product-right1">
            <div className="product-right-name">
                <h2>{name}</h2>
            </div>
            <div>
                <h4>Product ID: {productID}</h4>
            </div>
            {overview.trim() && <div>
                <h4>Overview:</h4>
                <p>{overview}</p>
            </div>}
            {specification.trim() && <div>
                <h4>Specifications:</h4>
                <ul>{lister(specification)}</ul>
            </div>}
            {features.trim() && <div>
                <h4>Features:</h4>
                <ol>{lister(features)}</ol>
            </div>}
            {performance.trim() && <div>
                <h4>Performance:</h4>
                <ul>{lister(performance)}</ul>
            </div>}
            {electricalChar.trim() && <div>
                <h4>Electrical Characteristics:</h4>
                <ul>{lister(electricalChar)}</ul>
            </div>}
            {physicalChar.trim() && <div>
                <h4>Physical Characteristics:</h4>
                <ul>{lister(physicalChar)}</ul>
            </div>}
            {environmentalChar.trim() && <div>
                <h4>Environmental Characteristics:</h4>
                <ul>{lister(environmentalChar)}</ul>
            </div>}
            {packaging.trim() && <div>
                <h4>Packaging:</h4>
                <p>{packaging}</p>
            </div>}
            {certifications.trim() && <div>
                <h4>Certifications:</h4>
                <ul>{lister(certifications)}</ul>
            </div>}
            <p>For more information or to request a quote, please contact our sales team (sales@econnecti.com)</p>
        </div>

    </div>);
};

export default Product;