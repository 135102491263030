import React from 'react';
import PhoneIcon from "../components/UI/phoneIcon";
import LocationIcon from "../components/UI/LocationIcon";
import EmailIcon from "../components/UI/EmailIcon";
import WebIcon from "../components/UI/WebIcon";

import './contactUs.css'

  
const ContactUs = () => {
  return (
    <div>
      <div className="contactus-header">
        <h1>Contact Us</h1>
      </div>
      <div className='container-contactus'>
        <div className='image'>
          <img
            className='imageIn'
            alt=""
            src={process.env.PUBLIC_URL + "/contactUsMap.png"}>
          </img>
        </div>
        <div className='container-contactus contactus-column'>
          <div>
            <h3>Head Quarters</h3>
            <p><LocationIcon/> #15 West 27th Street, New York, NY 10001</p>
            <p>_</p>
            <p><EmailIcon/> info@etechpro.us</p>
          </div>
          <div>
            <h3>R&D Center</h3>
            <p><LocationIcon/> #8 The Green STE R, Dover, Dalaware, DE 19901</p>
            <p><PhoneIcon/> +1 302-526-0500</p>
            <p><EmailIcon/> info@etechpro.us</p>
            <p><WebIcon/> www.etechpro.us</p>
          </div>
        </div>
      </div>
    </div>
  );
};
  
export default ContactUs;