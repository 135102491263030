import { NavLink } from "react-router-dom";
import { config } from "../../config";

const ProductCard = (props) => {
    
    const navlink = "/product/" + props._id

    return (
        <div className="productCard" id={props._id}>
            <img className="productCardImg" alt="Product" src={config.backendBASEurl + "/images/"+props.image}>
            </img>
            <p>{props.name}</p>
            <NavLink to={navlink}>
            <button type="button">
                Product Details
            </button>
            </NavLink>
        </div>
    );
};

export default ProductCard;