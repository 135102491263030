import React from "react";

const SubCatLeft = (props) => {
    const subcat = props.subcat; 
    const subcategories = subcat
    .map(subcat=><div 
        key={subcat.name}>
            <h4 style={{margin:0}} onClick={()=>props.toggle && props.scrollfunc(subcat._id)}>{subcat.name}</h4>
            {subcat.group?
                subcat.group
                .map(group=><h5 className="subcatgroup-left" key={group.name} onClick={()=>props.toggle && props.scrollfunc(group._id)}>{group.name}</h5>):""}
                </div>);
    return (<div className="subcat-left">
        {subcategories}
    </div>);
};

export default SubCatLeft;