// Import React
import React from "react";

// Import the external CSS file
import "./Footer.css";

// Import UI components
import PhoneIcon from "../UI/phoneIcon";
import LocationIcon from "../UI/LocationIcon";
import EmailIcon from "../UI/EmailIcon";
import WebIcon from "../UI/WebIcon";
 
// Define the Footer component
const Footer = () => {
  return (
  <>
    <div className="footer">
      <div className="flexbox">
        <div className="item">
          <h3>Contact Us:</h3>
          <h4>Head Quarters</h4>
          <p><LocationIcon/> #15 West 27th Street, New York, NY 10001</p>
          <p>_</p>
          <p><EmailIcon/> info@etechpro.us</p>
        </div>
        <div className="item">
          <h3 style={{"color":"#66EE41"}}>_</h3>
          <h4>R&D Center</h4>
          <p><LocationIcon/> #8 The Green STE R, Dover, Dalaware, DE 19901</p>
          <p><PhoneIcon/> +1 302-526-0500</p>
          <p><EmailIcon/> info@etechpro.us</p>
          <p><WebIcon/> www.etechpro.us</p>
        </div>
      </div>
      <div className="flexbox">
        <div className="item">
          <h3>Partners/Distributors:</h3>
          <h4>Middle East</h4>
          <h5>E-Connect Innovative Goods Wholes LLC</h5>
          <p><PhoneIcon/> +971 42694019</p>
          <p><PhoneIcon/> +971 509800120</p>
          <p><LocationIcon/> #536, Block-B, Dubai Economic Building, Deira, Dubai, UAE, P.O Box 187007</p>
          <p><EmailIcon/> Sales.@econnecti.com</p>
          <p><WebIcon/> www.econnecti.com</p>
        </div>
        <div className="item">
          <h3 style={{"color":"#66EE41"}}>_</h3>
          <h4>Asia Pacific</h4>
          <h5>Renovat INDIA</h5>
          <p><LocationIcon/> OTC Road, Bangalore, Karnataka, INDIA</p>
          <p><EmailIcon/> Sales.renovatindia@gmail.com</p>
          <p><PhoneIcon/> +91 96206798</p>
        </div>
      </div>
    </div>
    <div className="social">
      <div>
        <p>© 2023 Etechpro All Rights Reserved</p>
      </div>
      <div>
        <img
          src={process.env.PUBLIC_URL + "/instagram.png"}
          alt="Instagram"
          width="40"
          height="40"
        />
        <img
          src={process.env.PUBLIC_URL + "/whatsapp.png"}
          alt="WhatsApp"
          width="40"
          height="40"
        />
        <img
          src={process.env.PUBLIC_URL + "/linkedin.png"}
          alt="LinkedIn"
          width="46"
          height="46"
        />
      </div>
    </div>
  </>
  );
};

export default Footer;
