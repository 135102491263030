import axios from "axios";
import { useEffect, useState } from "react";
import { config } from "../../config";
import DisplayImageRow from "./DisplayImageRow";


const EditDisplayImage = () => {
    const [imageList,setImageList] = useState([]);
    const [refresh,setRefresh] = useState(false);

    const refreshEDI = ()=> setRefresh(!refresh);
    
    const handleAddSlot = (e) => {
        // check if valid image using e.target.files[0]
        if (imageList.length===5){ return null};
        const formdata = new FormData();
        formdata.append('image', e.target.files[0]);
        axios.post(config.backendBASEurl + "/api/homepageImages/",formdata).then(()=>refreshEDI()).catch(err=>console.log(err))
    }

    useEffect(()=>{axios.get(config.backendBASEurl + "/api/homepageImages/")
    .then((res)=>setImageList(res.data)).catch(()=>setImageList("Server Issue."));
    },[refresh]);

    const rows = Array.isArray(imageList)?imageList.map((i,s)=>
    <DisplayImageRow name={i.originalName} image={i.imagePath} _id={i._id} slot={s+1} handler={refreshEDI}/>):"";

    return (
        <div className="EditDisplayImageTable">
            <div className="EDIHeader">
                <h5 className="slot">Slot</h5>
                <h5 className="imagePreview">Image Preview</h5>
                <h5 className="imageName">Image Name</h5>
            </div>
            <div className="EDIBody">
                {rows}
                {imageList.length<5 && <div className="lastrow">
                <input type="file" id="filesAdd" style={{display:"none"}} accept="image/jpeg, images/jpg" onChange={e=>handleAddSlot(e)}/>
                <label className="addSlot" htmlFor="filesAdd"><p>Add Slot</p></label>
                </div>}
            </div>
        </div>
    );
};

export default EditDisplayImage;