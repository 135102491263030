import React, { useState } from 'react';
import './admin.css'
import EditDisplayImage from '../components/Admin/EditDisplayImage';
import EditProductList from '../components/Admin/EditProductList';
  
const Admin = () => {

  const [selected, setSelected] = useState("1");

  return (
    <>
    <div className='adminHeader'>
      <h1>Admin</h1>
    </div>
    <div className='adminBody'>
      <div className='adminNav'>
        <h4 onClick={()=>setSelected("1")} className={selected==="1"?"activeNav":""}>Edit Product List</h4>
        <h4 onClick={()=>setSelected("2")} className={selected==="2"?"activeNav":""}>Edit Display Image</h4>
      </div>
      {selected==="2"?<EditDisplayImage/>:""}
      {selected==="1"?<EditProductList/>:""}
    </div>
    </>
  );
};
  
export default Admin;