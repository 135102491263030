import { useState, useEffect } from "react";
import "./Slider.css";
import axios from "axios";
import { config } from "../../config";

const Slider = () => {
  
  const [sliderData, setSliderData] = useState([]);

  useEffect(() => {
    axios.get(config.backendBASEurl + "/api/homepageImages/").then((res)=>setSliderData(res.data));
  },[]);

  const [currentSlide, setCurrentSlide] = useState(0);
  const slideLength = sliderData.length;

  const autoScroll = true;
  let slideInterval;
  let intervalTime = 5000;

  const nextSlide = () => {
    setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
  };

  function auto() {
    slideInterval = setInterval(nextSlide, intervalTime);
  }

  useEffect(() => {
    setCurrentSlide(0);
  }, []);

  useEffect(() => {
    if (autoScroll) {
      auto();
    }
    return () => clearInterval(slideInterval);
  }, [currentSlide]);

  return (
    <div className="slider">
      {sliderData.map((slide, index) => {
        return (
          <div
            className={index === currentSlide ? "slide current" : "slide"}
            key={index}
          >
            {index === currentSlide && (
                <img src={config.backendBASEurl + "/homepage/"+slide.imagePath} alt="slide" className="image" />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Slider;