import {NavLink, useNavigate, useLocation} from 'react-router-dom'
import './addProductPage.css'
import { useState, useRef, useEffect } from 'react';
import axios from 'axios'; // import axios for making HTTP requests
import { config } from '../config';

const EditProductPage = () => {
    
    let location = useLocation();
    const formdataRef = useRef(new FormData());
    const navigate = useNavigate();

    const [formdataImage,setformdataImage] = useState(null); // change the initial state to null
    
    const _id = location.state._id;
    const [name, setName] = useState("");
    const [productId, setProductId] = useState("");
    const [overview, setOverview] = useState("");
    const [group, setGroup] = useState("");
    const [specifications, setSpecifications] = useState("");
    const [features, setFeatures] = useState("");
    const [performance, setPerformance] = useState("");
    const [packaging, setPackaging] = useState("");
    const [certifications, setCertifications] = useState("");
    const [imageBE, setImageBE] = useState("");

    useEffect(() => {
        axios.get(config.backendBASEurl + "/api/products/" + _id).then((res)=>{
            const { productID, name, overview, specification, features, performance, packaging, certifications, group, image } = res.data;
            setName(name);
            setGroup(group);
            setProductId(productID);
            setOverview(overview);
            setSpecifications(specification);
            setFeatures(features);
            setPerformance(performance);
            setPackaging(packaging);
            setImageBE(image);
            setCertifications(certifications);}).catch(()=>alert("Product does not exist."));
        },[]); 

    

    const handleAddProductImage = (e) => {
        setformdataImage(URL.createObjectURL(e.target.files[0])); // use URL.createObjectURL to create a blob URL for the image
        formdataRef.current.set('image', e.target.files[0]);
    }

    const handleEditProduct = () => {

        // check if all the values are populated and has a minimum length of 3 char
        if (name.length < 3 || productId.length < 3 || overview.length < 3 || specifications.length < 3 || features.length < 3 || performance.length < 3 || packaging.length < 3 || certifications.length < 3) {
            alert("Please fill all the fields with at least 3 characters");
            return;
        }

        // append all the values to formdata
        formdataRef.current.set('group',group);
        formdataRef.current.set('name', name);
        formdataRef.current.set('productID', productId);
        formdataRef.current.set('overview', overview);
        formdataRef.current.set('specification', specifications);
        formdataRef.current.set('features', features);
        formdataRef.current.set('performance', performance);
        formdataRef.current.set('packaging', packaging);
        formdataRef.current.set('certifications', certifications);

        axios.put(config.backendBASEurl + '/api/products/' + _id, formdataRef.current, {headers:{'Content-Type': 'multipart/form-data'}})
            .then(response => {
                setformdataImage("");
                for (let value of formdataRef.current.values()){
                    value=null
                }
                navigate("/admin");
            })
            .catch(error => {
                for (let value of formdataRef.current.values()){
                    value=null
                }
                alert(error); 
            });
    }
    
    return (
    <div className="AddProductBody">
        <div className='AddProductHeader'>
            <h1>Edit Product</h1>
        </div>
        <div className="AddProductForm">
            <div className="AddProductLeft">
                <img src={formdataImage?formdataImage:config.backendBASEurl + "/images/"+imageBE} alt="Upload Product Image"></img>
                <input type="file" id="ProductAdd" style={{display:"none"}} accept="image/jpeg, images/jpg" onChange={e=>handleAddProductImage(e)}/>
                <label className="addSlot" htmlFor="ProductAdd"><p>Change Image</p></label>
            </div>
            <div className="AddProductRight">
                <div className='AddProductRest'>
                    <h3>Name:</h3>
                    <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)}></input>
                </div>
                <div className='AddProductRest'>
                    <h4>Product ID:</h4>
                    <input type="text" id="productId" value={productId} onChange={(e) => setProductId(e.target.value)}></input>
                </div>
                <div className='AddProductRest'>
                    <h4>Overview:</h4>
                    <textarea id="overview" value={overview} onChange={(e) => setOverview(e.target.value)}></textarea>
                </div>
                <div className='AddProductRest'>
                    <h4>Specifications:</h4>
                    <textarea id="specifications" value={specifications} onChange={(e) => setSpecifications(e.target.value)}></textarea>
                </div>
                <div className='AddProductRest'>
                    <h4>Features:</h4>
                    <textarea id="features" value={features} onChange={(e) => setFeatures(e.target.value)}></textarea>
                </div>
                <div className='AddProductRest'>
                    <h4>Performance:</h4>
                    <textarea id="performance" value={performance} onChange={(e) => setPerformance(e.target.value)}></textarea>
                </div>
                <div className='AddProductRest'>
                    <h4>Packaging:</h4>
                    <textarea id="packaging" value={packaging} onChange={(e) => setPackaging(e.target.value)}></textarea>
                </div>
                <div className='AddProductRest'>
                    <h4>Certifications:</h4>
                    <textarea id="certifications" value={certifications} onChange={(e) => setCertifications(e.target.value)}></textarea>
                </div>
                <div className='AddProductButtons'>
                    <NavLink to= {{pathname:"/admin"}}><button className='red'>Cancel</button></NavLink>
                    <button className='green' onClick={handleEditProduct}>Edit Product</button>
                </div>
            </div>
        </div>
    </div>);
};

export default EditProductPage;
