import { useEffect, useState } from 'react';
import './EditProductList.css'
import axios from 'axios';
import { config } from '../../config';
import EPLCategory from './EPLCategory';
import EPLSubCat from './EPLSubCat';
import AddCatSection from './AddCatSection';
import AddSubCatSection from './AddSubCatSection';
import EPLGroup from './EPLGroup';
import AddGroup from './AddGroup';

const EditProductList = () => {

    const [productList,setProductList] = useState([]);
    const [refreshEPLv,setRefreshEPLv] = useState(false);
    const [selectedCat,setSelectedCat] = useState("");
    const [selectedSubCat,setSelectedSubCat] = useState("");

    const refreshEPL = ()=> setRefreshEPLv(!refreshEPLv);
    
    useEffect(()=>{axios.get(config.backendBASEurl + "/api/products/list")
    .then((res)=>setProductList(res.data)).catch(()=>setProductList([]));
    },[refreshEPLv]);

    useEffect(()=>{if (productList.length>0 && selectedCat==="") setSelectedCat("1");},[productList,selectedCat]);
    
    const EPLCategories = productList.length > 0 ?
        productList.map((c,i)=>
        <EPLCategory 
        name={c.name} 
        handler={setSelectedCat}
        handlerp={setSelectedSubCat} 
        index={i+1} 
        selected={selectedCat} 
        _id={c._id} 
        refresh={refreshEPL}/>) : "";

    let EPLSubCategories = "";
    
    useEffect(()=>{
        if (productList.length>0 && selectedCat && productList[Number(selectedCat)-1].subcategory && selectedSubCat === "") 
            {setSelectedSubCat("1");}
    },[productList,selectedCat,selectedSubCat]);
    
    if (productList.length>0 && selectedCat) {
        EPLSubCategories = productList[Number(selectedCat)-1].subcategory?
            productList[Number(selectedCat) -1].subcategory.map((s,j)=>
            <EPLSubCat 
            name={s.name} 
            handler={setSelectedSubCat} 
            index={j+1} 
            selected={selectedSubCat} 
            _id={s._id}
            refresh={refreshEPL}/>):"";
    };

    let EPLGroups = "";

    if (productList.length>0 && selectedCat && selectedSubCat && productList[Number(selectedCat)-1].subcategory.length>0
         && productList[Number(selectedCat)-1].subcategory[Number(selectedSubCat)-1].group &&
        productList[Number(selectedCat)-1].subcategory[Number(selectedSubCat)-1].group.length>0) {
        EPLGroups = productList[Number(selectedCat)-1].subcategory[Number(selectedSubCat)-1].group.map((g,k)=>
                    <EPLGroup 
                    name={g.name} 
                    products={g.product}
                    _id={g._id}
                    refresh={refreshEPL}
                    category={productList[Number(selectedCat)-1]._id}
                    subcategory={productList[Number(selectedCat)-1].subcategory[Number(selectedSubCat)-1]._id}/>)
    }

    return (
        <div className="EditDisplayImageTable">
            <div className="EDIHeader">
                <div className="EPLCat"><h4>Category</h4></div>
                <div className="EPLCat"><h4>Sub Category</h4></div>
                <div className="EPLGroup"><h4>Group</h4></div>
            </div>
            <div className='EPLBody'>
                <div className='EPLCatR'>
                    {EPLCategories}
                    {EPLCategories.length<4?<AddCatSection refresh={refreshEPL}/>:""}
                </div>
                <div className='EPLCatR'>
                    {EPLSubCategories}
                    {EPLSubCategories.length<5 && selectedCat && productList[Number(selectedCat)-1]?
                        <AddSubCatSection 
                        category={productList[Number(selectedCat)-1]._id} 
                        refresh={refreshEPL}/>:""}
                </div>
                <div className='EPLGroupR'>
                    {selectedCat && selectedSubCat && productList[Number(selectedCat)-1] 
                    && productList[Number(selectedCat)-1].subcategory[Number(selectedSubCat)-1]? 
                        <AddGroup 
                        category={productList[Number(selectedCat)-1]._id} 
                        subcat={productList[Number(selectedCat)-1].subcategory[Number(selectedSubCat)-1]._id}
                        refresh={refreshEPL}/>:""}
                    {EPLGroups}
                </div>
            </div>
        </div>
    );
};

export default EditProductList;