import { useState, useEffect, useRef } from "react";
import './CategoriesLeft.css'
import SubCatLeft from "./SubCatLeft";

const CategoriesLeft = (props) => {
    const [toggle, setToggle] = useState(true)
    const [heightEl, setHeightEl] = useState();

    const refHeight = useRef()

    useEffect(() => {
        setHeightEl(`${refHeight.current.scrollHeight}px`)
    }, [toggle])

    const toggleState = () => {
        setToggle(!toggle)
    }

    return (
        <div className={props.last?"accordion accordion-last":"accordion"}>

            <button 
            onClick={toggleState}
            className="accordion-visible">
                <h3>{props.name}</h3>
                <img 
                className={toggle ? "active": ""}
                alt=""
                src={process.env.PUBLIC_URL + "/chevron.png"} />
            </button>
            
            <div 
            className={toggle ? "accordion-toggle animated" : "accordion-toggle"}
            style={{height: toggle ? `${heightEl}` : "0px"}}
            ref={refHeight}
            >
                <SubCatLeft scrollfunc={props.scrollfunc} subcat={props.subcat?props.subcat:""} toggle={toggle} aria-hidden={toggle ? "true" : "false"}/>
            </div>
            
        </div>
    )  
};

export default CategoriesLeft;