
// Import React and React Router components
import React from "react";
import { NavLink } from "react-router-dom";

// Import the external CSS file
import "./Navbar.css";

const Navbar = (props) => {

  return (
    <>
      <nav className="nav">
        <img
          className="logo"
          src={process.env.PUBLIC_URL + "/EtechproLogo21.png"}
          alt="Logo"
        />
          <div style={{height:"8vh", alignItems:"center", display:"flex"}} onMouseEnter={()=>{props.dropdown(false)}}>
            <NavLink
              to="/"
              activeStyle={{ color: "#04B253" }}
              className="nav-link"
            >
              Home
            </NavLink>
          </div>
          <div style={{height:"8vh", alignItems:"center", display:"flex"}} onMouseEnter={()=>props.dropdown(true)}>
            <NavLink
              to="/products"
              activeStyle={{ color: "#04B253"}}
              className="nav-link"
              onClick={()=>props.dropdown(false)}
            >
              Products
            </NavLink>
          </div>
          <div style={{height:"62px", alignItems:"center", display:"flex"}} onMouseEnter={()=>{props.dropdown(false)}}>
            <NavLink
              to="/solutions"
              activeStyle={{ color: "#04B253" }}
              className="nav-link"
            >
              Solutions
            </NavLink>
          </div>
          <div className="nav-cont">
            <NavLink
              to="/warranty"
              activeStyle={{ color: "#04B253" }}
              className="nav-link"
            >
              Warranty
            </NavLink>
          </div>
          <div className="nav-cont">
            <NavLink
              to="/partners"
              activeStyle={{ color: "#04B253" }}
              className="nav-link"
            >
              Partners/Distributors
            </NavLink>
          </div>
          {/* Use margin-left: auto on the first link of the right side */}
          <div style={{ marginLeft: "auto" }} className="nav-cont">
            <NavLink
              to="/contactus"
              activeStyle={{ color: "#04B253" }}
              className="nav-link"
            >
              Contact Us
            </NavLink>
          </div>
          <div className="nav-cont">
            <NavLink
              to="/aboutus"
              activeStyle={{ color: "#04B253" }}
              className="nav-link"
            >
              About Us
            </NavLink>
          </div>
      </nav>
    </>
  );
};

export default Navbar;
