// Import React
import React from "react";
import PhoneIcon from "../components/UI/phoneIcon";
import LocationIcon from "../components/UI/LocationIcon";
import EmailIcon from "../components/UI/EmailIcon";
import WebIcon from "../components/UI/WebIcon";

import "./partners.css"

const Partners = () => {
  return (
    <div>
      <div className="partner-header">
        <h1>Partners/Distributors</h1>
      </div>
      <div className="container-partner" style={{backgroundImage: `url(${process.env.PUBLIC_URL + "/distributorsMap.png"})`, minHeight:"400px"}}>
        <div className="partner-left">
          <h3>Middle East</h3>
          <h4>E-Connect Innovative Goods Wholes LLC</h4>
          <p><PhoneIcon /> +971 42694019</p>
          <p><PhoneIcon /> +971 509800120</p>
          <p><LocationIcon /> #536, Block-B, Dubai Economic Building, Deira, Dubai, UAE, P.O Box 187007</p>
          <p><EmailIcon /> Sales.@econnecti.com</p>
          <p><WebIcon /> www.econnecti.com</p>
        </div>
        <div className="partner-right" style={{ marginLeft:"auto", maxWidth:"30%", minWidth:"300px"}}>
          <h3 style={{ textAlign: "right" }}>Asia Pacific</h3>
          <h4 style={{ textAlign: "right" }}>Renovat INDIA</h4>
          <p style={{ textAlign: "right" }}><LocationIcon /> OTC Road, Bangalore, Karnataka, INDIA</p>
          <p style={{ textAlign: "right" }}><EmailIcon /> Sales.renovatindia@gmail.com</p>
          <p style={{ textAlign: "right" }}><PhoneIcon /> +91 96206798</p>
        </div>
      </div>
    </div>
  );
};

export default Partners;
