import './products.css'
import CategoriesLeft from '../components/ProductsLeft/CategoriesLeft';
import CategoriesRight from "../components/ProductsRight/CategoriesRight";
import ScrollToTop from "../components/UI/ScrollToTop";
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
  
const Products = (props) => {

  const productList = props.productList;
  const location = useLocation();
  const selectedID = location.state;

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    
    if (element) {
      var headerOffset = 45;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + document.documentElement.scrollTop - headerOffset;
      
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  };
  
  useEffect(()=> {if (selectedID) handleClickScroll(selectedID)},[])
  
  const productsLeft = productList.map((category,i)=>
  <CategoriesLeft
    scrollfunc = {handleClickScroll} 
    name={category.name} 
    subcat={category.subcategory? category.subcategory:""} 
    last={i===productList.length-1?true:false}/>
    ) || "";

  const productsRight = productList.map((category)=>
  <CategoriesRight 
    name={category.name}
    _id={category._id} 
    subcat={category.subcategory? category.subcategory:""}/>) || "";

  return (
    <>
      <div className='products-header'>
        <h1>Products</h1>
      </div>
      <div className='products-cont1'>
        <div className='products-left'>
          {productsLeft}
        </div>
        <div className='products-right'>
          {productsRight}
        </div>
      </div>
      <ScrollToTop/> 
    </>
  );
};
  
export default Products;